import React, { Component } from "react"
import Layout from "../components/layout"
import { queryGraphql } from "../services/api"
import { buildBlocks } from "../services/builder"
import { PreviewBlocks } from "../templates/fragment"

class Previewroom extends Component {
  state = {
    loading: true,
    title: "",
    blocks: [],
    meta: {},
  }

  constructor(props) {
    super(props)
    if (typeof window !== "undefined") {
      const parsedUrl = new URL(window.location.href)
      const id = parsedUrl.searchParams.get("id")
      const token = parsedUrl.searchParams.get("token")
      const endpoint = parsedUrl.searchParams.get("endpoint")

      const blocks = PreviewBlocks

      const queryObject = {
        query: `
                {
                    room(id: "${id}", idType: DATABASE_ID) {
                        id
                        slug
                        title
                        Meta {
                          fieldGroupName
                          metaDescription
                          metaTitle
                          ogDescription
                          ogTitle
                          twitterCardType
                          twitterDescription
                          twitterTitle
                          twitterImage {
                            uri
                          }
                          ogImage{
                            uri
                          }                            
                        }
                        PageCTA {
                          actionType
                          ctaText
                          fieldGroupName
                          link {
                            url
                          }
                        }
                        amenities {
                          name
                          iconUri
                        }
                        roomType
                        RoomData {
                          excerpt
                          listImage {
                            sourceUrl
                          }
                          price
                        }
                        ... on BlockEditorContentNode {
                            blocks {
                                ... on CoreBlock {
                                  reusableBlock {
                                    __typename
                                    ... on ReusableBlock {
                                      id
                                      blocks {
                                        ${blocks}
                                        innerBlocks {
                                            name
                                            ${blocks}
                                            innerBlocks {
                                                name
                                                ${blocks}
                                                innerBlocks {
                                                    name
                                                    ${blocks}                            
                                                }                                     
                                            }                          
                                        }                                         
                                      }
                                    }
                                  }
                                } 
                                name
                                ${blocks}
                                innerBlocks {
                                    name
                                    ${blocks}
                                    innerBlocks {
                                        name
                                        ${blocks}
                                        innerBlocks {
                                            name
                                            ${blocks}                            
                                        }                                     
                                    }                          
                                }    
                            }                     
                        }  
                    },
                    themeOptions {
                      accommodationActive
                      roomBookingCTAText
                      roomBookingImage
                      roomBookingImageText
                      roomBookingLink
                      tableBookingAccountId
                      tableBookingActive
                      tableBookingBrandId
                      tableBookingCTAText
                      tableBookingImage
                      tableBookingImageText
                      tableBookingRestaurantId
                      liveResSiteId
                    } 
                }
                `,
      }
      this.createContent(queryObject, token, endpoint)
    }
  }

  createContent = (queryObject, token, endpoint) => {
    queryGraphql(queryObject, token, endpoint, response => {
      console.log(response)
      const room = response.data.data.room
      if (room === null) {
        this.setState({
          error: true,
          errorMessage:
            "Unable to fetch room, try refreshing the admin panel page before clicking preview.",
        })
      } else {
        const pageContext = {
          nextPath: "",
          prevPath: "",
        }
        const blocks = buildBlocks(room.blocks, {roomType: room.roomType, amenities: room.amenities, ...room.RoomData}, pageContext)
        this.setState({
          loading: false,
          blocks: blocks,
          title: room.title,
          meta: room.Meta,
          themeOptions: response.data.data.themeOptions,
        })
      }
    })
  }

  render() {
    if (this.state.loading === true) {
      return (
        <Layout meta={{}} title="Preview" pageContext={{ wedding: false }}>
          <h1>Preview</h1>
          <p>Loading...</p>
          {this.state.error === false ? (
            ""
          ) : (
            <p className={"error"}>{this.state.errorMessage}</p>
          )}
        </Layout>
      )
    } else {
      return (
        <Layout meta={this.state.meta} title={`Preview: ${this.state.title}`}>
          {this.state.blocks.map(block => {
            return block
          })}
        </Layout>
      )
    }
  }
}

export default Previewroom
